import React from 'react';
import styles from './css/AboutUs.module.css';
import Reviews from "./Reviews";

const AboutUs = () => {
    return (
        <section id="about" className={styles.aboutSection}>

                <h1>
                    We specialize in crafting stunning, user-friendly websites that drive
                    <span className={styles.redText}> business growth </span>
                    and leave a
                    <span className={styles.redText}> lasting impression </span>
                </h1>
            <Reviews />
            <p>

                Our expertise lies in website design and development, seamlessly blending aesthetics with functionality.
                From custom-built websites to responsive designs optimized for all devices, we ensure your online presence stands out.
            </p>

            <p> Beyond development, we offer tailored digital strategies, including SEO, branding, and content optimization,
                to help your business thrive in the digital landscape.</p>


        </section>
    );
};

export default AboutUs;
