import React, { useState } from 'react';
import styles from './css/FormSection.module.css';
import Modal from './Modal';

const FormSection = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        details: '',
        location: '',
        budgetRange: '',
        projectType: '',
        projectDeadlines: '',
    });
    const [showModal, setShowModal] = useState(false); // State для модального вікна

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.details || !formData.projectType) {
            alert('Please fill out all required fields.');
            return;
        }

        const payload = {
            name: formData.name || '',
            email: formData.email || '',
            details: formData.details || '',
            location: formData.location || '',
            budgetRange: formData.budgetRange || '',
            projectType: formData.projectType || '',
            projectDeadlines: formData.projectDeadlines || '',
        };

        fetch(`${process.env.REACT_APP_API_URL}/api/orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setShowModal(true); // Показати модальне вікно
                } else {
                    alert('Failed to submit order. Please try again.');
                }
            })
            .catch((error) => {
                console.error('Error submitting order:', error);
                alert('An error occurred. Please try again.');
            });
    };

    const handleModalClose = () => {
        setShowModal(false); // Закрити модальне вікно
        onClose(); // Закрити форму
    };

    return (
        <section className={styles.formSection} id="form">
            <button className={styles.closeButton} onClick={onClose}>
                Close
            </button>
            <h1>Let’s Discuss Your Project</h1>
            <p>Fill out the form below, and we’ll get back to you shortly!</p>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.leftColumn}>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name here"
                        required
                    />
                    <label htmlFor="location">Location:</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        placeholder="Enter your location here"
                    />
                    <label htmlFor="budgetRange">Budget Range:</label>
                    <input
                        type="text"
                        id="budgetRange"
                        name="budgetRange"
                        value={formData.budgetRange}
                        onChange={handleChange}
                        placeholder="Estimated Budget"
                    />
                </div>
                <div className={styles.rightColumn}>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your email here"
                        required
                    />
                    <label htmlFor="projectType">Project Type:</label>
                    <input
                        type="text"
                        id="projectType"
                        name="projectType"
                        value={formData.projectType}
                        onChange={handleChange}
                        placeholder="Ecommerce store"
                        required
                    />
                    <label htmlFor="projectDeadlines">Project Deadlines:</label>
                    <input
                        type="text"
                        id="projectDeadlines"
                        name="projectDeadlines"
                        value={formData.projectDeadlines}
                        onChange={handleChange}
                        placeholder="Timeline (if any)"
                    />
                </div>
                <div className={styles.fullWidthField}>
                    <label htmlFor="details">Message / Description:</label>
                    <textarea
                        id="details"
                        name="details"
                        rows="4"
                        value={formData.details}
                        onChange={handleChange}
                        placeholder="Tell us more about your project"
                        required
                    />
                </div>
                <button type="submit" className={styles.submitButton}>
                    Submit Inquiry
                </button>
            </form>
            {showModal && (
                <Modal
                    message="Your request has been submitted successfully. We will review it within 4-6 hours."
                    onClose={handleModalClose}
                />
            )}
        </section>
    );
};

export default FormSection;
