import styles from "./css/OurServices.module.css";
import React from "react";

const OurServices = () => {
    return (
        <div id="services" className={styles.mainediv}>
            <h1 className={styles.topich1}>Our services</h1>
            <table>
                <tr>
                    <td>
                        <div className={styles.icontext}>
                            <p className={styles.numerate}>1</p>
                            <div>
                                <span className={styles.topic}>Custom Website Design & Development: </span>
                                We craft visually appealing, user-friendly websites that reflect your brand’s identity and engage your audience.
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className={styles.icontext}>
                            <p className={styles.numerate}>2</p>
                            <div>
                            <span className={styles.topic}> E-commerce Integration: </span>
                        Set up a secure online store to sell your products with integrated payment gateways and inventory management.
                        </div>
                        </div>
                        </td>
                </tr>
                <tr>
                    <td>
                        <div className={styles.icontext}>
                            <p className={styles.numerate}>3</p>
                            <div>
                            <span className={styles.topic}>Responsive & Mobile Optimization: </span>
                        Our websites are designed to perform seamlessly across all devices, ensuring a smooth experience for every visitor.
                        </div>
                        </div>
                        </td>

                    <td>
                        <div className={styles.icontext}>
                            <p className={styles.numerate}>4</p>
                            <div>
                                <span className={styles.topic}>Website Maintenance & Support: </span> We
                                offer ongoing support to ensure your website stays up-to-date, secure, and running
                                smoothly.
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={styles.icontext}>
                            <p className={styles.numerate}>5</p>
                            <div>
                                <span className={styles.topic}>Content Management Systems (CMS): </span>
                                We build websites with easy-to-use CMS platforms, empowering you to update your content
                                with ease.
                            </div>
                        </div>
                    </td>
                    <td>
                        <div  className={styles.icontext}>
                            <p className={styles.numerate}>6</p>
                            <div>
                            <span className={styles.topic}>Branding & Digital Strategy: </span>
                        Need help shaping your brand or digital marketing strategies? We provide expert guidance to elevate your business online.
                        </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    )
}
export default OurServices;