import React, { useEffect, useState } from 'react';
import styles from './css/AdminOrders.module.css';

function AdminOrders({ token }) {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/api/orders`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.status === 401 || res.status === 403) {
                        setError('Access denied. Please log in again.');
                        return [];
                    }
                    return res.json();
                })
                .then((data) => setOrders(data))
                .catch((err) => setError('Failed to fetch orders.'));
        }
    }, [token]);

    const deleteOrder = (id) => {
        if (!window.confirm('Are you sure you want to delete this order?')) return;

        fetch(`${process.env.REACT_APP_API_URL}/api/orders/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    setOrders((prev) => prev.filter((order) => order._id !== id));
                } else {
                    setError('Failed to delete the order.');
                }
            })
            .catch(() => setError('Failed to delete the order.'));
    };

    return (
        <div className={styles.container}>
            <h2>Orders</h2>
            {error && <p className={styles.error}>{error}</p>}
            <table className={styles.table}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Details</th>
                    <th>Location</th>
                    <th>Budget</th>
                    <th>Type</th>
                    <th>Deadline</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {orders.map((order) => (
                    <tr key={order._id}>
                        <td>{order.name}</td>
                        <td>{order.email}</td>
                        <td>{order.details}</td>
                        <td>{order.location}</td>
                        <td>{order.budgetRange}</td>
                        <td>{order.projectType}</td>
                        <td>{order.projectDeadlines}</td>
                        <td>
                            <button
                                className={styles.deleteButton}
                                onClick={() => deleteOrder(order._id)}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default AdminOrders;
