import React, { useState, useEffect } from 'react';
import styles from './css/Reviews.module.css';

const Reviews = () => {
    const [reviews, setReviews] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        // Завантаження відгуків з API
        fetch(`${process.env.REACT_APP_API_URL}/api/reviews`)
            .then((res) => res.json())
            .then((data) => setReviews(data))
            .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        if (reviews.length > 1) {
            const interval = setInterval(() => {
                setIsAnimating(true); // Починаємо анімацію
                setTimeout(() => {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
                    setIsAnimating(false); // Завершуємо анімацію
                }, 500); // Час збігається з CSS-анімацією
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [reviews]);

    if (reviews.length === 0) {
        return <p>Немає відгуків</p>;
    }

    const currentReview = reviews[currentIndex];

    // Функція для відображення зірок
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span key={i} className={`${styles.star} ${i <= rating ? styles.filled : ''}`}>
          ★
        </span>
            );
        }
        return stars;
    };

    return (
        <div className={styles.reviewContainer}>

            <div className={`${styles.review} ${isAnimating ? styles.slideOut : styles.slideIn}`}>
                <div className={styles.reviewimagecontainer}><img className={styles.reviewimage} src="images/reviews/quotes.png" alt="reviewsetImage"/></div>

                <h4>{currentReview.name}</h4>
                <p className={styles.profession}>{currentReview.profession}</p>
                <p className={styles.content}>{currentReview.content}</p>
                <div className={styles.rating}>{renderStars(currentReview.rating)}</div>
            </div>
        </div>
    );
};

export default Reviews;
