import React, { useState } from 'react';
import styles from './css/Header.module.css';

const Header = ({ sections, onNavigate }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className={styles.header}>
            <div
                className={styles.logo}
                onClick={() => onNavigate(0)} // Переходить до секції "profession"
            >
                Elyntrix
            </div>

            {/* Бургер-іконка - тільки для мобільної версії */}
            <div className={styles.burgerIcon} onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>

            {/* Навігація для десктопу */}
            <nav className={styles.nav}>
                {sections.slice(1).map((section, index) => (
                    <button
                        key={section.id}
                        className={styles.navButton}
                        onClick={() => onNavigate(index + 1)}
                    >
                        {section.id.charAt(0).toUpperCase() + section.id.slice(1)}
                    </button>
                ))}
            </nav>

            {/* Бургер-меню - тільки для мобільної версії */}
            <div className={`${styles.burgerMenu} ${isMenuOpen ? styles.open : ''}`}>
                <button className={styles.closeButton} onClick={toggleMenu}>&times;</button>
                {sections.slice(1).map((section, index) => (
                    <button
                        key={section.id}
                        className={styles.menuItem}
                        onClick={() => {
                            onNavigate(index + 1); // Оскільки ми пропускаємо перший елемент
                            toggleMenu();
                        }}
                    >
                        {section.id.charAt(0).toUpperCase() + section.id.slice(1)}
                    </button>
                ))}
            </div>
        </header>
    );
};

export default Header;