import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AdminPage from './components/AdminPage';

import styles from './App.module.css';

import FullScreenSections from "./components/FullScreenSections";


function App() {
    return (
        <Router>


                <div className={styles.container}>
                <main>
                    <Routes>
                        <Route path="/" element={
                            <>

                                <FullScreenSections />

                            </>
                        } />
                        <Route path="/admin" element={<AdminPage />} />
                    </Routes>
                </main>
                </div>



        </Router>
    );
}

export default App;
