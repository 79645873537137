import React from 'react';
import styles from './css/ProfessionSection.module.css';

const ProfessionSection = ({ onOpenFormSection }) => {
    const handleButtonClick = () => {
        if (onOpenFormSection) {
            onOpenFormSection();
        }
    };

    return (
        <section className={styles.professionSection} id="profession">
            <h1 className={styles.maintext}>
                <span>Take Your Business to the Next Level</span>
            </h1>
            <div>
                <p className={styles.description}>
                    We create modern, standout websites designed to elevate your brand,
                    promote your business, and showcase your products like never before
                </p>
            </div>
            <button className={styles.openFormButton} onClick={handleButtonClick}>
                Get in touch
            </button>
        </section>
    );
};

export default ProfessionSection;
