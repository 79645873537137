import React, { useState, useEffect } from 'react';

const AdminProjects = ({ token }) => {
    const [projects, setProjects] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        link: '',
        image: null, // Додаємо поле для фото
    });
    const [editingProject, setEditingProject] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/projects`)
            .then(res => res.json())
            .then(data => setProjects(data))
            .catch(err => console.error(err));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] }); // Зберігаємо файл
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const url = editingProject
            ? `${process.env.REACT_APP_API_URL}/api/projects/${editingProject._id}`
            : `${process.env.REACT_APP_API_URL}/api/projects`;

        const method = editingProject ? 'PUT' : 'POST';

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('link', formData.link);
        if (formData.image) formDataToSend.append('image', formData.image);

        fetch(url, {
            method,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formDataToSend,
        })
            .then(res => res.json())
            .then(data => {
                if (editingProject) {
                    setProjects(projects.map(proj => proj._id === data._id ? data : proj));
                } else {
                    setProjects([...projects, data]);
                }
                setFormData({ name: '', link: '', image: null });
                setEditingProject(null);
            })
            .catch(err => console.error(err));
    };

    const handleDelete = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(() => setProjects(projects.filter(proj => proj._id !== id)))
            .catch(err => console.error(err));
    };

    return (
        <div>
            <h2>Управління проектами</h2>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <input
                    type="text"
                    name="name"
                    placeholder="Назва проекту"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    type="url"
                    name="link"
                    placeholder="Посилання на проект"
                    value={formData.link}
                    onChange={handleChange}
                    required
                />
                <input
                    type="file"
                    name="image"
                    onChange={handleFileChange}
                    accept="image/*"
                    required={!editingProject}
                />
                <button type="submit">{editingProject ? 'Оновити' : 'Додати'}</button>
            </form>

            <ul>
                {projects.map(project => (
                    <li key={project._id}>
                        <img
                            src={`${process.env.REACT_APP_API_URL}${project.imageUrl}`}
                            alt={project.name}
                            style={{ width: '100px', height: 'auto' }}
                        />
                        <a href={project.link} target="_blank" rel="noopener noreferrer">{project.name}</a>
                        <button onClick={() => setEditingProject(project)}>Редагувати</button>
                        <button onClick={() => handleDelete(project._id)}>Видалити</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminProjects;
