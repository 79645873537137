import React, { useState, useEffect } from 'react';

const AdminReviews = ({ token }) => {
    const [reviews, setReviews] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        profession: '',
        content: '',
        rating: 5,
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/reviews`)
            .then((res) => res.json())
            .then((data) => setReviews(data))
            .catch((err) => console.error(err));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/api/reviews`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((newReview) => {
                setReviews([newReview, ...reviews]);
                setFormData({ name: '', profession: '', content: '', rating: 5 });
            })
            .catch((err) => console.error(err));
    };

    const handleDelete = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/reviews/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(() => setReviews(reviews.filter((review) => review._id !== id)))
            .catch((err) => console.error(err));
    };

    return (
        <div>
            <h2>Управління відгуками</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder="Ім'я"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="profession"
                    placeholder="Професія"
                    value={formData.profession}
                    onChange={handleChange}
                    required
                />
                <textarea
                    name="content"
                    placeholder="Відгук"
                    value={formData.content}
                    onChange={handleChange}
                    required
                ></textarea>
                <input
                    type="number"
                    name="rating"
                    placeholder="Оцінка (1-5)"
                    value={formData.rating}
                    onChange={handleChange}
                    min="1"
                    max="5"
                    required
                />
                <button type="submit">Додати відгук</button>
            </form>

            <ul>
                {reviews.map((review) => (
                    <li key={review._id}>
                        <strong>{review.name}</strong> ({review.profession})
                        <p>{review.content}</p>
                        <p>Оцінка: {review.rating}</p>
                        <button onClick={() => handleDelete(review._id)}>Видалити</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminReviews;
