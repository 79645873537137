import React, { useEffect, useState } from 'react';
import styles from './css/AdminPage.module.css';
import AdminProjects from './AdminProjects';
import AdminReviews from './AdminReviews';
import AdminOrders from './AdminOrders';

function AdminPage() {
    const [admins, setAdmins] = useState([]);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: ''
    });

    const [loginData, setLoginData] = useState({ username: '', password: '' });
    const [token, setToken] = useState(localStorage.getItem('adminToken') || '');

    useEffect(() => {
        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/api/admin`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        setToken('');
                        localStorage.removeItem('adminToken');
                        return [];
                    }
                    return res.json();
                })
                .then(data => setAdmins(data))
                .catch(err => console.error(err));
        }
    }, [token]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleLoginChange = (e) => {
        const { name, value } = e.target;
        setLoginData(prev => ({ ...prev, [name]: value }));
    };

    const handleAdminSubmit = (e) => {
        e.preventDefault();
        if (!token) return alert('Спершу увійдіть в систему');
        fetch(`${process.env.REACT_APP_API_URL}/api/admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then(newAdmin => {
                if (newAdmin.message) {
                    alert(newAdmin.message);
                } else {
                    setAdmins([...admins, newAdmin]);
                    setFormData({ username: '', password: '', email: '' });
                }
            })
            .catch(err => console.error(err));
    };

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/api/admin/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(loginData)
        })
            .then(res => res.json())
            .then(data => {
                if (data.token) {
                    setToken(data.token);
                    localStorage.setItem('adminToken', data.token);
                    setLoginData({ username: '', password: '' });
                } else {
                    alert(data.message || 'Помилка логіну');
                }
            })
            .catch(err => console.error(err));
    };

    return (
        <div className={styles.container}>
            {!token && (
                <>
                    <h2>Увійдіть як адміністратор</h2>
                    <form onSubmit={handleLoginSubmit} className={styles.loginForm}>
                        <label htmlFor="loginUsername">Username:</label>
                        <input
                            id="loginUsername"
                            type="text"
                            name="username"
                            value={loginData.username}
                            onChange={handleLoginChange}
                            required
                        />
                        <label htmlFor="loginPassword">Password:</label>
                        <input
                            id="loginPassword"
                            type="password"
                            name="password"
                            value={loginData.password}
                            onChange={handleLoginChange}
                            required
                        />
                        <button type="submit" className={styles.submitButton}>Логін</button>
                    </form>

                </>
            )}

            {token && (
                <>
                    <h2>Адміністрація</h2>
                    <ul className={styles.adminList}>
                        {admins.map(admin => (
                            <li key={admin._id}>
                                {admin.username} - {admin.email}
                            </li>
                        ))}
                    </ul>

                    <h3>Додати нового адміністратора</h3>
                    <form onSubmit={handleAdminSubmit} className={styles.adminForm}>
                        <label htmlFor="adminUsername">Username:</label>
                        <input
                            id="adminUsername"
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleFormChange}
                            required
                        />
                        <label htmlFor="adminPassword">Password:</label>
                        <input
                            id="adminPassword"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleFormChange}
                            required
                        />
                        <label htmlFor="adminEmail">Email:</label>
                        <input
                            id="adminEmail"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleFormChange}
                        />
                        <button type="submit" className={styles.submitButton}>Створити</button>
                    </form>

                    <AdminProjects token={token} />
                    <AdminReviews token={token} />
                    <AdminOrders token={token} />

                    <button
                        className={styles.logoutButton}
                        onClick={() => {
                            setToken('');
                            localStorage.removeItem('adminToken');
                        }}>
                        Вийти
                    </button>

                </>
            )}
        </div>
    );
}

export default AdminPage;
